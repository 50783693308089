.navbar {
  background-color: gray;
  margin-top: auto;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to top, rgb(218, 218, 218), rgb(255, 255, 255));
  padding-bottom: 10px;
}

.navbar .logo {
  margin-right: auto;
}

.navbar .nav-links {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-family: arial;
}

.navbar .nav-links > * {
  margin-right: 30px;
  color: black;
  text-decoration: none;
  font-size: 20px;
  padding: 20px;
}

.navbar .nav-links > *:hover {
  color: navy;
  border-bottom: 4px solid navy;
}

.nav-phone {
  padding-right: 20px;
  font-family: arial;
}

.container {
  position: relative;
  background-color: rgb(238, 238, 238);
  max-width: 100%;
}

.container .slideshow {
  position: relative;
}

.container section {
  position: relative;
  z-index: 2;
}

.container section h1 {
  position: relative;
  color: white;
  text-align: center;
  z-index: 3;
  font-family: Arial;
  font-weight: 900;
  font-size: 50px;
  background-color: rgba(0, 0, 0, 0.235);
  outline: none;
  border-radius: 4px;
  padding: 10px;
}

.container section p {
  position: relative;
  z-index: 3;
}

.buttons {
  position: absolute;
  bottom: calc(12%);
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.buttons button {
  margin: 10px;
  background-color: navy;
  color: white;
  padding: 16px 28px;
  font-size: 20px;
  cursor: pointer;
  border-radius: 4px;
  font-family: arial;
}

.buttons button:hover {
  background-color: rgb(0, 0, 206);
}

.slideshow {
  position: relative;
  height: 450px;
  overflow: hidden;
}

.slide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: transform 4s ease-in-out;
}

.slide.active {
  opacity: 1;
}

.slide-text {
  position: absolute;
  top: calc(38%);
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  z-index: 1;
  font-family: Arial;
  font-weight: 900;
  font-size: 58px;
  background-color: rgba(0, 0, 0, 0.235);
  outline: none;
  border-radius: 6px;
  padding: 10px;
}

.head-photo {
  padding: 0;
  width: 100%;
  height: 450px;
  object-fit: cover;
}

.dots {
  position: absolute;
  top: calc(14%);
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dot {
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  pointer-events: auto;
}

.dot.active {
  width: 24px;
  height: 24px;
  background-color: navy;
}

.dot:hover {
  width: 24px;
  height: 24px;
  background-color: rgb(0, 0, 180);
}


.pause-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 60px;
  height: 60px;
  background-color: rgba(255, 255, 255, 0.709);
  border: none;
  font-size: 30px;
  color: black;
  cursor: pointer;
  transition: color 0.3s ease;
}

.pause-button:hover {
  color: grey;
}

.container {
  display: grid;
  grid-template-columns: 70% 30%;
}

#about-section {
  margin-top: -25px;
  text-align: center;
  padding-bottom: 20px;
  font-family: arial;
}

.partner-section {
  padding: 10px;
  text-align: center;
  font-family: arial;
}

#about-section h1 {
  color: white;
  background-color: navy;
  border-radius: 0px;
}

#about-section h3 {
  font-size: 30px;
  width: calc(90%);
  margin-left: calc(5%);
  text-align: center;
  padding-bottom: 10px;
}

#about-section p {
  font-size: 22px;
  width: calc(90%);
  margin-left: calc(5%);
  padding-bottom: 20px;
}

.partner-section h1 {
  text-decoration: underline;
  text-underline-offset: 5px;
  font-size: 30px;
}

.partner-section h2 {
  margin-bottom: -20px;
  font-size: 30px;
}

.partner-section h4 {
  font-style: italic;
  font-size: 20px;
}

.partner-section h5 {
  margin-top: -15px;
  margin-bottom: -10px;
  font-size: 15px;
}

.partner-section img {
  max-width: 100%;
}


#services-section {
  text-align: justify;
  font-size: 20px;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  background-color: rgb(238, 238, 238);
  padding-bottom: 30px;
  font-family: arial;
}

#services-section h1 {
  color: white;
  background-color: navy;
  width: 98.1%;
  padding: 10px;
  margin-top: 0;
  column-span: all;
  border-radius: 0px;
}

#services-section p {
  column-span: all;
  width: calc(90%);
  margin-left: calc(5%);
  font-size: 22px;
}

#services-section ul {
  columns: 1;
  width: calc(90%);
  margin-left: calc(5%);
}

#services-section li {
  padding: 5px 10px;
  text-align: left;
}

.link-button {
  display: inline-block;
  padding: 15px 40px;
  background-color: navy;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: calc(100% - 60px);
}

.link-button:hover {
  background-color: #0056b3;
}

#top-section {
  padding-left: 10px;
  font-family: arial;
}

#top-section h2 {
  text-decoration: underline;
}

.quick-links {
  margin-top: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  font-size: 20px;
  padding-left: 20px;
  margin-bottom: 40px;
  font-family: arial;
}

.quick-links a {
  display: block;
  color: black;
  text-decoration: none;
}

.quick-links a:hover,
.sections a:hover {
  color: navy;
  text-decoration: underline;
}

.sections section {
  padding-bottom: 15px;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: arial;
}

.sections h1 {
  background-color: lightgray;
}

.sections {
  font-size: 20px;
}

.sections a {
  color: black;
}

.footer {
  background: linear-gradient(to bottom, rgb(218, 218, 218), rgb(247, 247, 247));
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: arial;
}

.footer img {
  padding-left: 20px;
}

.footer .footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.footer .footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  row-gap: 5px;
  font-size: 20px;
}

.footer .footer-links a {
  color: black;
  text-decoration: none;
  font-size: 22px;
  padding: 10px;
}

.footer .footer-links > *:hover {
  color: navy;
}

.footer h3 {
  text-align: right;
  line-height: 3px;
  padding-right: 20px;
}

.contactform {
  padding-bottom: 40px;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  padding-left: 80px;
  font-family: Arial;
}

.contactform textarea, input, select {
  font-family: Arial;;
  font-size: 18px;
}

.contact-head {
  padding-left: 80px;
  padding-bottom: 20px;
  font-family: Arial;
}

.contact-head p {
  font-size: 20px;
}

.contact-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: navy;
  color: white;
  text-decoration: none;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: -40px;
  margin-bottom: 10px;
  margin-left: 80px;
  font-size: 16px; /* Define the desired font size */
  font-family: Arial;
}


.contact-button:hover {
  background-color: blue;
}

.sendfile {
  padding-left: 80px;
  padding-right: 80px;
  font-size: 20px;
  text-align: justify;
  font-family: Arial;
}

label {
  display: inline-block;
  width: 150px;
}

input,
textarea,
select {
  width: 400px;
  padding: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input[type="submit"] {
  width: auto;
  padding: 15px 40px;
  background-color: navy;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: Arial;
}

input[type="submit"]:hover {
  background-color: blue;
}

.career-section {
  font-family: arial;
}

.career-section h1 {
  font-size: 35px;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 5px;
  padding-bottom: 30px;
}

.career-section p {
  font-size: 20px;
  row-gap: 5px;
  width: calc(90%);
  margin-left: calc(5%);
  padding-bottom: 10px;
}

.career-section h2 {
  text-align: center;
}

@media screen and (max-width: 768px) {

  .navbar .nav-links > *:hover {
    border-bottom: 2px solid navy;
    line-height: 1rem;
  }

  .navbar .logo {
    max-width: 120px;
  }
  
  .navbar .nav-links > * {
    margin-right: 0px;
    font-size: 13px;
    padding: 6px;
  }
  
  .nav-phone {
    font-size: 10px;
  }
  
  .container section h1 {
    font-size: 20px;
    padding: 10px;
  }
  
  .buttons {
    bottom: calc(2%);
  }
  
  .buttons button {
    margin: 10px;
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 4px;
  }
  
  .slideshow {
    height: 250px;
  }
  
  .slide-text {
    top: calc(42%);
    font-size: 22px;
    padding: 10px;
    width: calc(70%);
  }
  
  .head-photo {
    height: 300px;
  }
  
  .dots {
    top: calc(8%);
  }
  
  .dot {
    width: 15px;
    height: 15px;
    margin: 0 5px;
  }
  
  .dot.active {
    width: 18px;
    height: 18px;
  }
  
  .pause-button {
    width: 40px;
    height: 40px;
    font-size: 14px;
    color: black;
  }
  
  .pause-button:hover {
    color: grey;
  }

  #about-section {
    margin-top: -10px;
    padding-bottom: 10px;
  }
  
  .partner-section {
    padding: 10px;
  }
  
  
  #about-section h3 {
    font-size: 20px;
    width: calc(90%);
    margin-left: calc(5%);
    margin-top: -10px;
  }
  
  #about-section p {
    font-size: 11px;
    width: calc(90%);
    margin-left: calc(5%);
    margin-top: -10px;
  }
  
  .partner-section h1 {
    text-decoration: underline;
    text-underline-offset: 5px;
    font-size: 15px;
  }
  
  .partner-section h2 {
    margin-bottom: -10px;
    font-size: 15px;
  }
  
  .partner-section h4 {
    font-style: italic;
    font-size: 12px;
  }
  
  .partner-section h5 {
    margin-top: -5px;
    margin-bottom: 0px;
    font-size: 12px;
  }
  
  .partner-section img {
    max-width: 100;
  }
  
  #services-section {
    text-align: justify;
    font-size: 10px;
    columns: 2;
    -webkit-columns: 2;
    -moz-columns: 2;
    background-color: rgb(238, 238, 238);
    padding-bottom: 30px;
  }
  
  #services-section h1 {
    color: white;
    background-color: navy;
    margin-top: 0;
    column-span: all;
    border-radius: 0px;
  }
  
  #services-section p {
    column-span: all;
    font-size: 12px;
  }
  
  #services-section ul {
    columns: 1;
    width: calc(90%);
    margin-left: calc(5%);
  }
  
  #services-section li {
    text-align: left;
  }
  
  .link-button {
    padding: 8px 20px;
    margin-left: calc(100%);
  }
  
  #top-section {
    padding-left: 10px;
    font-size: 10px;
  }
  
  #top-section h2 {
    font-size: 20px;
  }
  
  .quick-links {
    margin-top: 10px;
    gap: 15px;
    font-size: 14px;
    padding-left: 20px;
    margin-bottom: 40px;
  }

  .sections section {
    padding-bottom: 15px;
    margin-bottom: 30px;
  }
  
  .sections {
    font-size: 10px;
  }
  
  .footer {
  background: linear-gradient(to bottom, rgb(218, 218, 218), rgb(247, 247, 247));
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: arial;
}

.footer img {
  max-width: 150px;
}

.footer .footer-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

.footer .footer-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 0px;
  font-size: 13px;
}

.footer .footer-links a {
  color: black;
  text-decoration: none;
  font-size: 13px;
}

.footer .footer-links > *:hover {
  color: navy;
}

.footer h3 {
  text-align: right;
  line-height: 3px;
  padding-right: 10px;
  font-size: 10px;
}
  
  .contactform {
    padding-bottom: 40px;
    font-size: 12px;
  }
  
  .contactform {
    gap: 2px;
    padding-left: 20px;
  }
  
  .contact-head {
    padding-left: 20px;
    padding-bottom: 20px;
  }
  
  .contact-head p {
    font-size: 14px;
  }
  
  .contact-button {
    padding: 15px 30px;
    margin-right: -10px;
    margin-bottom: 10px;
    margin-left: 20px;
    font-size: 16px; 
  }
  
  .sendfile {
    padding-left: 20px;
    font-size: 14px;
  }
  
  label {
    width: 80px;
  }
  
  input,
  textarea,
  select {
    width: 300px;
    padding: 5px;
  }
  
  input[type="submit"] {
    padding: 15px 40px;
    font-size: 15px;
  }

  .career-section p {
    font-size: 9px;
    margin-top: -5px;
    text-align: center;
  }

  .career-section {
    width: calc(80%);
    margin-left: calc(10%);
  }

  .career-section h1 {
    font-size: 20px;
    margin-bottom: -15px;
  }

  .career-section h2 {
    font-size: 10px;
  }

  #services-section {
    width: calc(90%);
  }
}

@media (min-width: 600px) and (max-width: 1200px) {
  
  .buttons {
    bottom: calc(5%);
  }

  .slide-text {
    top: calc(43%);
    font-size: 40px;
  }
  
  .dots {
    top: calc(35%);
  }

  .pause-button {
    font-size: 22px;
  }

  .nav-phone {
    font-size: 12px;
  }
}

@media (min-width: 1200px) {

  .buttons {
    bottom: calc(5%);
  }

  .slide-text {
    top: calc(45%);
  }

  .dots {
    top: calc(16%);
  }

  .career-section p {
    padding-bottom: 10px;
  }
}